<template>
  <validation-observer ref="simpleRules">
    <b-row class="match-height">
      <b-col
        cols="12"
        md="4"
      >
        <announcements />
        <customer-info v-if="userData.id_customers" />
        <customer v-else /></b-col>
      <b-col
        cols="12"
        md="8"
      >
        <payment-form />
      </b-col>
    </b-row>
    <payment3-d v-if="getPaymentSendForm.modal" />
  </validation-observer>
</template>
<script>
import { ValidationObserver, localize } from 'vee-validate'
import {
  BRow, BCol,
} from 'bootstrap-vue'
import { required } from '@validations'
import Announcements from '@/views/Payment/Components/Announcements.vue'
import Customer from '@/views/Payment/Components/Customer.vue'
import PaymentForm from '@/views/Payment/Components/PaymentForm.vue'
import Payment3D from '@/views/Payment/Components/Payment3D.vue'
import CustomerInfo from '@/views/Payment/Components/CustomerInfo.vue'

export default {
  name: 'Index',
  components: {
    CustomerInfo,
    BRow,
    BCol,
    ValidationObserver,
    Customer,
    PaymentForm,
    Payment3D,
    Announcements,
  },
  data() {
    return {
      required,
      locale: 'tr',
      modalShow: false,
      userData: JSON.parse(localStorage.getItem('userData')),

    }
  },
  computed: {
    form() {
      return this.$store.getters['payment/getPaymentForm']
    },
    getPaymentSendForm() {
      return this.$store.getters['payment/getPaymentSendForm']
    },
  },
  created() {
    localize(this.locale)
    this.resetForm()
    this.setCompany()
  },
  methods: {
    resetForm() {
      this.$store.dispatch('payment/resetForm')
    },
    setCompany() {
      // müşteri kullanıcısı ise
      if (this.userData.id_customers) {
        this.$store.dispatch('payment/setCompany', {
          id_customers: this.userData.id_customers,
        })
          .then(response => {
            if (response) {
              this.form.nameSurname = response.company
              this.id_customers = response.id_customers
            }
          })
      }
    },
  },
}
</script>
